import React from "react";
import BlogsList from "../../component/AdminPanel/Blogs/BlogsList";

const BlogsListPage = () => {
  return (
    <div>
      <BlogsList />
    </div>
  );
};

export default BlogsListPage;
