import React from 'react'
import WomenSoccer from '../component/WomenSoccer'
import MainPage from '../component/WomenSoccer/MainPage'

const WomenSoccerPage = () => {
  return (
    <div><MainPage/></div>
  )
}

export default WomenSoccerPage