import React from "react";
import Faqs from "../../component/AdminPanel/MediaManagement/Faqs";

const FaqsPageAdmin = () => {
  return (
    <div>
      <Faqs />
    </div>
  );
};

export default FaqsPageAdmin;
