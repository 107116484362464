import React from "react";
import AddEvents from "../../../component/AdminPanel/Events/AddEvents";

const AddEventsPage = () => {
  return (
    <div>
      <AddEvents />
    </div>
  );
};

export default AddEventsPage;
