import React from "react";
import MediaUpload from "../../component/AdminPanel/MediaManagement/MediaUpload";
const MediaUploadPage = () => {
  return (
    <div>
      <MediaUpload />
    </div>
  );
};

export default MediaUploadPage;
