import React from "react";
import AdminLogin from "../../component/AdminPanel/AdminLogin";

const AdminLoginPage = () => {
  return (
    <div>
      <AdminLogin />
    </div>
  );
};

export default AdminLoginPage;
