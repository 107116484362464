import React from "react";
import AdminDashboard from "../../component/AdminPanel/AdminDashboard";

const AdminDashboardPage = () => {
  return (
    <div>
      <AdminDashboard />
    </div>
  );
};

export default AdminDashboardPage;
