import React from "react";
import AllFaqs from "../../component/AdminPanel/MediaManagement/Faqs/AllFaqs";

const AllFaqsPage = () => {
  return (
    <div>
      <AllFaqs />
    </div>
  );
};

export default AllFaqsPage;
